.wrapper select {
  font-size: 0.6em;
}

.title {
  padding-left: 20px;
  padding-right: 20px;
}

.map {
  z-index: 0;
  border-radius: 3px;
  height: 700px;
  border: 1px solid var(--surface4Color);
}

.spacedItem {
  margin-bottom: 10px;
}

.spacer {
  height: 20px;
  clear: both;
  width: 1px;
}

.section {
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}

.buttonSet {
  text-align: right;
}

.meteorologyTable {
  padding-top: 10px;
}

.staticUserTable {
  width: 100%;
  margin-bottom: var(--largeMargin);
  user-select: none;
}

.staticUserTable thead > tr > th {
  background: transparent !important;
  padding-top: var(--smallMargin);
  vertical-align: middle;
}

.staticUserTable td {
  word-break: break-all;
}

.userTable {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  table-layout: fixed;
}

.userTable tr:hover td {
  background-color: #fafafa;
}

.userTable th {
  text-align: left;
  font-weight: bold;
}

.userTable tr {
  margin-top: 10px;
  user-select: none;
  cursor: pointer;
}

.tableBatchActions td {
  padding-top: 32px;
}

.userTable tr:hover td:first-child {
  background-color: #fafafa;
}

.userTable td:last-child {
  padding-right: 0px;
  text-align: right;
}

.userTable th:last-child {
  text-align: right;
}

.messageBody {
  font-size: 0.85rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.addNewUsersModal {
  max-width: 850px !important;
  max-height: 800px;
}

.editParentBody {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0px 0px 10px 0px !important;
}

.editParentsModal {
  max-width: 450px !important;
  max-height: 550px !important;
}

.userTable td {
  padding-bottom: 4px;
  padding-top: 4px;
}

.userTable th {
  padding-bottom: 6px;
  padding-top: 12px;
}

.userTable td:first-child,
.userTable th:first-child {
  width: 30px;
  text-align: center;
}

.addUsersBody {
  padding: 0 !important;
  overflow: hidden;
  background: white;
}

.dialogContent {
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.error {
  background: var(--errorColor);
  padding: 10px;
  margin-bottom: 10px;
  color: white;
  border-radius: 4px;
}

.parentLocationText {
  color: var(--textColor);
  font-size: 0.9em;
}

.locationHeaderTable {
  font-size: 0.9em;
}

.locationHeaderTable td {
  padding-left: 2px;
  padding-bottom: 10px;
  padding-right: 30px;
}

.treeSelector {
  width: 100%;
  height: 100%;
}

.treeContainer {
  min-height: 100% !important;
  padding: 10px;
  background: transparent !important;
  box-sizing: border-box;
}

.mapArea {
  flex: 1;
  margin-right: 20px;
}

.sidebarArea {
  width: 350px;
  flex-shrink: 0;
  padding: 15px 15px;
  background: #fdfdfd;
  border: 1px solid var(--surface4Color);
  border-radius: 4px;
}

.wrapper {
  display: flex;
}

.buttonHeader {
  margin-top: 30px;
}

.buttonRow {
  display: flex;
}

.inputItem,
.infoValue {
  flex: 1;
}

.infoValue {
  font-size: 0.9em;
}

.infoTable {
  width: 100%;
}
.infoTable td > div {
  width: 100%;
}

.infoTable td:first-child {
  padding-right: 40px;
}

.infoTable td:only-child {
  padding-right: 0px;
}

.infoTable td {
  padding: 10px 0 0 0;
  font-size: 0.9em;
  height: 23px;
}

.infoTable td label {
  font-weight: 300;
  color: var(--textColor);
}

.fullHeight {
  height: 100%;
  margin-bottom: 0px;
}

.infoTable button {
  width: 100%;
}

.searchField {
  width: 100%;
  box-sizing: border-box;
}

.searchContainer {
  background: var(--surface5Color);
  padding: var(--standardMargin);
  border: 1px solid var(--borderColor);
  flex-shrink: 0;
  box-sizing: border-box;
  flex-grow: 0;
}

.fixedContainer {
  display: flex;
}

.tableContainer {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0px var(--standardMargin) var(--standardMargin) var(--standardMargin);
  box-sizing: border-box;
}

.addAllCheckbox {
  margin-left: 2px;
}

.noUsersText {
  padding-top: var(--standardMargin);
}

.infoColumn {
  min-width: 95px;
}
