.tagSearchContainer {
  background-color: var(--surface5Color);

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex: 1 0 1px;
  width: 100%;
  height: auto !important;
  padding: var(--smallMargin);
  border-bottom: 1px solid #eaeaea;
  flex-shrink: 0;
}

.tagSearchSelect {
  width: 100%;
}

.tagSearchLabel {
  display: block;
  padding-right: var(--smallMargin);
  flex-grow: 0;
  font-size: var(--labelFontSize);
  color: var(--labelColor);
  font-weight: var(--labelWeight);
}

.tagSearchSelectable {
  flex-grow: 1;
  width: 100%;
}

.searchFieldContainer {
  height: 45px;
  font-size: 0.9em;
  border: 0;
  border-radius: 0;
  margin-bottom: 0px;
  background-color: var(--surface5Color);
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding-right: 10px;
  flex-shrink: 0;
}

.searchFieldContainer svg {
  right: 7px;
}

.searchField::placeholder {
  color: #888;
}

.searchField {
  font-size: 1.3em;
  color: var(--surface1Color);
  height: 100%;
}
