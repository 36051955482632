:root {
  --defaultSpeed: 0.2s;
  --dataTableSpeed: 0.3s;
  --spinnerSpeed: 1.1s;
  --sidebarSpeed: 0.15s;
  --modalSpeed: 0.2s;
  --loadingScreenSpeed: 0.3s;
  --toastSpeed: 0.25s;
  --tooltipSpeed: 0.1s;
  --jumpyEasing: cubic-bezier(0.6, 0.4, 0.2, 1.4);
  --motionEasing: cubic-bezier(0.23, 1, 0.32, 1);
  --placeholderColor: #aaaaaa;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --tableBorderColor: #dfdfdf;
  --tableBorderStyle: 1px solid #dfdfdf;
  --toolbarMenuButtonColor: #5f6368;
  --buttonHoverColor: rgb(34, 184, 201);
  --buttonActiveColor: rgb(31, 150, 163);
  --greyButtonBorderColor: rgb(193, 193, 193);
  --tableEvenRowColor: #fafafa;
  --tableOddRowColor: #ffffff;
  --primary1Color: #ea1c0a;
  --primary2Color: #d51607;
  --primary3Color: #b00402;
  --successColor: #229625;
  --failureColor: #ff0000;
  --failureColorTransparent: #ff0000c0;
  --gasColor: #1E90FF;
  --airTempColor: #32CD32;
  --solarIrradianceColor: #FF9C0C;
  --errorColor: #d51607;
  --heatingColor: #C6342D;
  --coolingColor: #1EA2B1;
  --electricityColor: #CCBB23;
  --heatingSecondaryColor: #6b0000;
  --coolingSecondaryColor: #4E5F71;
  --electricitySecondaryColor: #837800;
  --primaryTint1Color: tint($primary1Color, 75 %);
  --primaryTint2Color: tint($primary1Color, 50 %);
  --primaryTint3Color: tint($primary1Color, 25 %);
  --accent1Color: #1ea2b1;
  --accent1ColorHover: #1b909d;
  --accent2Color: #2b91a3;
  --accent3Color: #00738B;
  --accent4Color: #4E5F71;
  --accent5Color: #8797A4;
  --active1Color: #e3e000;
  --accentTint1Color: tint($accent1Color, 75 %);
  --accentTint2Color: tint($accent1Color, 50 %);
  --accentTint3Color: tint($accent1Color, 25 %);
  --darkSide1Color: #242b2d;
  --darkSide2Color: #323a3b;
  --darkSide2ColorTransparent: #323a3bc0;
  --darkSide3Color: #434a4c;
  --darkSide4Color: #656c6e;
  --labelColor: #666;
  --textColor: #404040;
  --iconColor: #404040;
  --surface1Color: #39393a;
  --surface2Color: #666666;
  --surface3Color: #8f9192;
  --surface4Color: #e8e8e8;
  --surface5Color: #f6f6f6;
  --surface6Color: #fafafa;
  --surface7Color: #fdfdfd;
  --pendingColor: #e88b09;
  --borderColor: #d3d3d3;
  --darkTextColor: #ffffff;
  --darkBorderColor: #444;
  --darkBackgroundColor: #222;
  --selectControlInactiveColor: hsl(0, 0%, 80%);
  --selectControlActiveColor: hsl(0, 0%, 40%);
  --selectFocusBackgroundColor: #efefef;
  --disabledBackgroundColor: #eee;
  --semiTransparentWhite: rgba(255,255,255,0.5);
  --surface6Shadow: 2px 4px 8px 0px rgba(0,0,0,0.15);
  --inputBackground: #ffffff;
  --backdropColor: rgba(0, 0, 0, 0.4);
  --defaultIconColor: #8f9192;
  --leftSidebarIconColor: rgba(255, 255, 255, 0.4);
  --tooltipBackground: rgba(0, 0, 0, 0.8);
  --dividerColor: #d3d3d3;
  --cardShadow: 0 0px 2px rgba(0,0,0,0.15);
  --selectControlFocusShadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(69, 122, 187, 0.5);
  --overlayShadow: 0px 4px 32px rgba(0,0,0,0.2);
  --navBarShadow: 0px 2px 4px rgba(0,0,0,0.3);
  --selectShadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  --overlayFakeShadowGradient: linear-gradient(to right, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
  --panelShadow: 0 2px 2px rgba(0,0,0,0.10);
  --disabledOpacity: 0.7;
  --severity0Color: rgba(234, 29, 10, 1);
  --severity1Color: rgba(255, 145, 20, 1);
  --severity2Color: rgba(247, 214, 49, 1);
  --transparent: rgba(0,0,0,0);
  --appFont: 'Segoe UI', '-apple-system', 'BlinkMacSystemFont','Open Sans', 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
  --monoFont: 'SFMono-Regular',Consolas,'Liberation Mono',Menlo,'Courier New',monospace;
  --navBarZIndex: 500;
  --innerContentZIndex: 600;
  --sideBarZIndex: 600;
  --modalZIndex: 1000;
  --modalOverlayZIndex: 1001;
  --loadingScreenZIndex: 9999;
  --toastZIndex: 10000;
  --tooltipZIndex: 10000;
  --iconButtonWidth: 36px;
  --iconButtonHeight: 33px;
  --footerHeight: 50px;
  --headerHeight: 75px;
  --toolbarHeight: 65px;
  --toolbarSpacing: 20px;
  --leftSidebarWidth: 75px;
  --navRowHeight: 50px;
  --standardMargin: 14px;
  --insetMargin: 3px;
  --smallMargin: 7px;
  --largeMargin: 21px;
  --tableVerticalMargin: 9px;
  --iconTextMargin: 10px;
  --plainBoxPadding: 10px;
  --hugeMargin: 28px;
  --tableBorderRadius: 5px;
  --maxListPageWidth: 1400px;
  --contractedViewBreakpointWidth: 1600px;
  --contractedViewWidth: 1366px;
  --graphPageSmallViewport: 1280px;
  --labelWeight: 500;
  --labelFontSize: 13px;
  --tableFontSize: 14px;
  --baseSvgFontSize: 16px;
  --textInputFontSize: 0.81rem;
  --alternateSvgFontSize: 15px;
  --subtitleFontSize: 0.85em;
  --contentSidebarWidth: 340px;
  --mobileBreakpoint: 650px;
  --tabletBreakpoint: 1200px;
  --modalBreakpointHeight: 760px;
  --dataTableRowHeightStandard: 38px;
  --dataTableRowHeightWithButtons: 49px;
  --dataTableRowHeightWithSelect: 51px;
  --dataTableRowHeightWithCheckbox: 38px;
  --modalContractedHeaderHeight: 45px;
  --modalContractedFooterHeight: 45px;
  --modalHeaderHeight: 48px;
  --modalFooterHeight: 54px;
  --modalCornerRadius: 6px;
  --maxModalHeight: 80vh;
  --maxModalWidth: 900px;
  --tableHeaderHeight: 25px;
  --borderWidth: 1px;
  --dividerWidth: 1px;
  --horizontalColumnWidth: 235px;
  --treeDialogWidth: 450px;
  --treeDialogHeight: 500px;
  --spinnerLargeSize: 72px;
  --spinnerMediumSize: 42px;
  --spinnerSmallSize: 32px;
  --spinnerInlineSize: 6px;
  --spinnerTinySize: 22px;
  --spinnerTreeViewSize: 16px;
  --sidebarExpandedLimit: 1800px;

}
