.debugInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--largeMargin);
  margin-top: -25px;
}

.flexibleSpace {
  flex-grow: 1;
}

.production {
  color: var(--failureColor);
}
