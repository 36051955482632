.dialog {
  max-width: 660px !important;
  min-height: 800px !important;
  max-height: 800px !important;
}

@media (max-height: 700px) {
  .dialog {
    min-height: 80vh !important;
    max-height: 80vh !important;
  }
}
