.marker {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-50%, 0);
  background-color: var(--accent1Color);
  padding: 0 10px 5px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  transition: all var(--defaultSpeed) ease-in-out;

  &.isLoading {
    cursor: default;
  }
}

.isCurrentNode,
.isCurrentNode .arrow {
  background-color: var(--accent3Color);
}

.hasFocus {
  z-index: 2;
}

.icon {
  display: inline-flex;
  width: 100%;
  height: 100%;
  margin-top: var(--smallMargin);
  justify-content: center;
  align-items: center;

  &.hasNoSignalValue {
    margin-top: var(--smallMargin);
  }
}

.icon svg {
  width: 22px;
  height: auto;
}

.signalValue {
  margin-top: var(--insetMargin);
  transition: all var(--defaultSpeed) ease-in-out;
  text-align: center;
  white-space: nowrap;
  color: white;
  font-size: var(--tableFontSize);
  transition: all var(--defaultSpeed) ease-in-out;
}

.arrowContainer {
  position: absolute;
  content: '';
  width: 40px;
  height: 20px;
  bottom: -19px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  overflow: hidden;
}

.arrow {
  width: 17px;
  height: 17px;
  transform: rotate(45deg);
  background: var(--accent1Color);
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
  margin: -10px auto 0;
}

.marker {
  z-index: 1;
  transition: opacity var(--defaultSpeed) ease-in-out;
}

.hasFocus {
  z-index: 2;
}

.fade {
  opacity: 0.5;
}

.popup {
  z-index: 4;
}

.gridIcon {
  color: white;
  font-size: 26px;
  padding: 2px 4px;
}
