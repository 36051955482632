.pageHeader {
  width: 100%;
  display: flex;
  padding-top: var(--largeMargin);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  z-index: var(--navBarZIndex);
  background: white;
  justify-content: space-between;
}

.pageHeaderInner {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;

  color: var(--labelColor);
}

.pageHeaderInner.bottomPadding {
  padding-bottom: var(--largeMargin);
}

.pageHeaderInner a {
  color: #999;
  text-decoration: none;
}

.pageHeaderHelpTooltip {
  display: inline-flex;
  padding-right: var(--largeMargin);
  margin-top: -5px;
}

.multipleItems {
  padding-top: var(--standardMargin);
  margin-top: initial;
}

.title {
  user-select: none;
  margin-left: var(--largeMargin);
  display: flex;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 400;
}
